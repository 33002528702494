import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import richText from "../serializers/richText"
import classNames from "classnames"
import Layout from "../components/Layout"

import SEO from "../components/seo"

export default () => {
  const data = useStaticQuery(graphql`
    {
      sanityBookPage(_id: { eq: "bookPage" }) {
        title
        description: _rawDescription
        buyLink
        images {
          image {
            asset {
              url
              fluid {
                base64
                srcWebp
                srcSetWebp
              }
            }
          }
        }
        seo {
          metaTitle
          metaDescription
          openGraphImage {
            asset {
              url
            }
          }
          openGraphTitle
          openGraphDescription
          twitterTitle
          twitterDescription
          twitterImage {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  const { title, description, buyLink, images = [], seo } = data.sanityBookPage

  return (
    <Layout>
      <SEO title="A House In the Country" {...seo} />
      <section className="book grid-container contained pt0 pt2--md">
        <div className="row">
          <div className="col c12">
            <div className="book__images df fw">
              {images.map(item => (
                <picture>
                  <source
                    srcSet={`${item.image.asset.url}?w=1000&auto=format&q=75`}
                    media="(min-width: 600px)"
                  />
                  <img
                    alt={title}
                    className="db"
                    src={`${item.image.asset.url}?w=600&auto=format`}
                  />
                </picture>
              ))}
            </div>
          </div>

          <aside className="col c6--lg pt5 pt7--md pt10--lg">
            <div className="rich-text serif--sm serif--md--md serif--lg--lg">
              <BlockContent blocks={description} serializers={richText} />
            </div>
          </aside>

          <div className="col c6--lg pt5 pt7--md pt10--lg">
            <a
              href={buyLink}
              rel="noopener noreferrer"
              target="_blank"
              className="link--underline serif--lg serif--xl--md"
            >
              Buy Now
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}
